import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { analytics } from 'infra/analytics'
import { useScoutMyRequest } from 'apis/hooks'
import { useIsLoggedIn } from 'utils/hooks'
import { kakao } from 'infra/kakao'
import { FloatingButton } from 'components/scouts/common/styles'
import { useRouter } from 'next/router'
import { Balloon, BalloonType } from 'components/common/balloon'

export const ScoutLandingCtaBtn: React.FC<{
  observingTarget?: (node: HTMLButtonElement) => void
}> = ({ observingTarget }) => {
  const { data: myRequest } = useScoutMyRequest()
  const isLoggedIn = useIsLoggedIn()
  const router = useRouter()
  return (
    <Link
      href={
        myRequest && myRequest.educations.length > 0
          ? '/scouts/my-profile'
          : '/scouts/request-form/job-search-status'
      }
      style={{ width: '100%' }}
      onClick={(e) => {
        if (!isLoggedIn) {
          e.preventDefault()
          router.replace('?start-scout').then(() => kakao.login())
        }
        analytics.track('click_sr_landing_cta_btn')
      }}
    >
      <CTAButton ref={observingTarget}>
        {isLoggedIn
          ? '👉🏻 스카우트 제안받기'
          : '👉🏻 3초만에 가입하고 스카우트 제안받기'}
      </CTAButton>
    </Link>
  )
}

const CTAButton = styled(FloatingButton)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.h3};
    width: 515px;
    border-radius: 50px;
    padding: 24px;
  }
`

export const GuideBalloon = styled(Balloon).attrs({
  variant: BalloonType.BLACK,
})<{ isDisableAnimation?: boolean }>`
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  white-space: nowrap;
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.desc};
    ${(p) =>
      !p.isDisableAnimation &&
      `animation: guideBalloonSlideDesktop 0.8s ease-in-out infinite;`}
    @keyframes guideBalloonSlideDesktop {
      from {
        top: -30px;
      }
      50% {
        top: -35px;
      }
      to {
        top: -30px;
      }
    }
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.small};
    ${(p) =>
      !p.isDisableAnimation &&
      `animation: guideBalloonSlideMobile 0.8s ease-in-out infinite;`}
    @keyframes guideBalloonSlideMobile {
      from {
        top: -25px;
      }
      50% {
        top: -30px;
      }
      to {
        top: -25px;
      }
    }
  }
`
